import { useCallback } from 'react';

import {
	useQuery as useReactQuery,
	useQueryClient as useReactQueryClient,
	useMutation as useReactMutation,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import isEmpty from 'utils/isEmpty';

import api from 'services/api';

const serialize = (obj, prefix) => {
	var str = [],
		p;
	for (p in obj) {
		if (obj.hasOwnProperty(p)) {
			var k = prefix ? prefix + '[' + p + ']' : p,
				v = obj[p];
			str.push(
				v !== null && typeof v === 'object'
					? serialize(v, k)
					: encodeURIComponent(k) + '=' + encodeURIComponent(v),
			);
		}
	}
	return str.join('&');
};

const getQueryKeys = (key) => {
	if (!key) {
		return [];
	}

	const keys = typeof key === 'string' ? [key] : key || [];
	return keys;
};

const getApiUrl = (keys, params) => {
	let url = `/${keys.join('/')}`;

	if (!isEmpty(params)) {
		const keys = Object.keys(params);
		keys.forEach((key) => {
			if (typeof params[key] === 'undefined') {
				delete params[key];
			}
		});

		url += '?' + serialize(params);
	}
	return url;
};

export function getQueryFilter(values, excludes) {
	if (typeof values === 'object') {
		const filters = { ...values };

		if (excludes) {
			if (typeof excludes === 'string') {
				excludes = excludes.split(',');
			}

			excludes.forEach((key) => {
				delete filters[key];
			});
		}

		const keys = Object.keys(filters);

		if (keys.length > 0) {
			keys.forEach((key) => {
				if (typeof filters[key] === 'undefined') {
					delete filters[key];
				} else if (
					typeof filters[key] === 'object' &&
					typeof filters[key].getTime === 'function'
				) {
					filters[key] = filters[key].getTime();
				}
			});

			return {
				filter: filters,
			};
		}
	}
	return undefined;
}

export const useQueryClient = () => {
	return useReactQueryClient();
};

export const useQuery = (keys, options = {}) => {
	let { query, enabled = true, ...others } = options;

	const baseKeys = getQueryKeys(keys);

	let queryProps = {};

	if (enabled && baseKeys.length > 0) {
		const queryKeys = [...baseKeys];

		if (query) {
			queryKeys.push(query);
		}

		if (baseKeys.length > 1) {
			if (!baseKeys[baseKeys.length - 1]) {
				baseKeys[baseKeys.length - 1] = 0;
			}
		}

		queryProps = {
			queryKey: queryKeys,
			queryFn: async () => {
				if (baseKeys.length > 1) {
					if (baseKeys[baseKeys.length - 1] === 0) {
						return Promise.resolve({
							isNew: true,
							...(others.placeholderData || {}),
						});
					} else if (
						baseKeys[baseKeys.length - 1] === 'search' &&
						!query.filter?.search
					) {
						return Promise.resolve([]);
					}
				}

				const url = getApiUrl(baseKeys, query);

				const { data } = await api.get(url);

				return data;
			},
			...others,
		};
	} else {
		queryProps = { enabled: false };
	}

	return useReactQuery(queryProps);
};

export const useData = (key, options = {}) => {
	const { data } = useQuery(
		key,
		Object.assign(
			{},
			{
				gcTime: Infinity,
				refetchOnMount: false,
				refetchOnReconnect: false,
			},
			options,
		),
	);
	return data;
};

export const useMutation = (options = {}) => {
	const queryClient = useQueryClient();

	const props = {
		mutationFn: async ({
			key,
			path,
			id,
			data,
			method,
			headers,
			updateCaches,
			invalidateQueries,
			onSuccess,
			onError,
		}) => {
			const baseKeys = getQueryKeys(key);

			const apiKeys = [...baseKeys];

			let ids = [];

			if (!data) {
				method = method || 'delete';
				if (id) {
					if (Array.isArray(id)) {
						ids = [...id];
					} else {
						ids = [id];
					}
					apiKeys.push(ids.join(','));
				}
			} else if (data.isNew) {
				method = method || 'post';
				delete data.isNew;
			} else if (data.id) {
				method = method || 'put';
				apiKeys.push(data.id);
			} else {
				method = method || 'post';
			}

			if (!path) {
				path = getApiUrl(apiKeys);
			}

			try {
				// Delay calling an API, waiting for UI updates.
				await new Promise((r) => setTimeout(r, 500));

				const response = await api[method](
					path,
					data,
					headers ? { headers } : undefined,
				);

				const newData = response.data;

				if (newData.error) {
					if (typeof onError === 'function') {
						onError(newData.error.message);
					}
					return newData;
				} else {
					if (updateCaches) {
						if (updateCaches === true) {
							updateCaches = baseKeys;
						}

						if (method === 'delete') {
							queryClient.setQueryData(updateCaches, (old = []) =>
								old.filter((item) => !ids.includes(item.id)),
							);
						} else if (method === 'post') {
							queryClient.setQueryData(
								updateCaches,
								(old = []) => [...old, newData],
							);
						} else {
							queryClient.setQueryData(updateCaches, (old = []) =>
								old.map((item) =>
									item.id === newData.id ? newData : item,
								),
							);
						}
					}

					if (invalidateQueries) {
						if (Array.isArray(invalidateQueries)) {
							queryClient.invalidateQueries({
								queryKey: invalidateQueries,
							});
						} else {
							queryClient.invalidateQueries(invalidateQueries);
						}
					}

					if (typeof onSuccess === 'function') {
						onSuccess({ newData, queryClient });
					}

					return newData;
				}
			} catch (error) {
				if (typeof onError === 'function') {
					onError(error.message);
				}
				throw error;
			}
		},
		...options,
	};

	return useReactMutation(props);
};

export function useSelect(key, options = {}) {
	const items = useData(key, options);

	const select = useCallback(
		(cb) => {
			return items?.find(cb);
		},
		[items],
	);

	const selectAll = useCallback(() => {
		return items;
	}, [items]);

	return { select, selectAll };
}

const client = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 5 * 1000,
			gcTime: 12 * 60 * 60 * 1000,
			refetchOnWindowFocus: false,
		},
	},
});

const QueryProvider = ({ children }) => {
	return (
		<QueryClientProvider client={client}>
			{children}
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
};

export default QueryProvider;
