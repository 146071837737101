import MainLayout from 'layout/MainLayout';

import { getRoutes } from './router';

const Routes = {
	path: '/',
	element: <MainLayout />,
	children: getRoutes([
		{
			path: '',
		},
		{
			path: 'approvals',
			children: [
				{
					path: 'approvals/transactions',
					context: ['new', 'edit'],
					list: true,
				},
				{
					path: 'approvals/orders',
					context: ['new', 'edit'],
					list: true,
				},
			],
		},
		{
			path: 'posts',
			context: ['new', 'edit', 'view'],
			list: true,
		},
	]),
};

export default Routes;
