import {
	useContext,
	createContext,
	useRef,
	useState,
	useEffect,
	useCallback,
} from 'react';

import { useNavigate } from 'react-router-dom';

import { APP_LOGIN_PATH } from 'config';

import { getLocalAccessToken, getTokenExpirationDate } from 'services/token';

import {
	login as signin,
	logout as signout,
	register as signup,
	getProfile,
	getRefreshToken,
} from 'services/auth';

const AuthContext = createContext();

const AuthProvider = ({ children, authenticationRequired = false }) => {
	const token = getLocalAccessToken();
	const expirationDate = getTokenExpirationDate();

	const [user, setUser] = useState(null);

	const isLoading = useRef(false);

	const navigate = useNavigate();

	const toHome = useCallback(() => {
		navigate('/');
	}, [navigate]);

	const toLogin = useCallback(() => {
		navigate(APP_LOGIN_PATH);
	}, [navigate]);

	const login = useCallback(
		async (data, cb = {}) => {
			const { onSuccess, onError } = cb;

			try {
				const response = await signin(data);

				const { error } = response.data;

				if (!error) {
					if (typeof onSuccess === 'function') {
						onSuccess();
					}

					toHome();
				} else if (typeof onError === 'function') {
					onError(error.message);
				}
			} catch (error) {
				if (typeof onError === 'function') {
					onError(error.message);
				}
			}
		},
		[toHome],
	);

	const logout = useCallback(
		async (onSuccess, onError) => {
			try {
				const response = await signout(user);

				const { error } = response.data;

				if (!error) {
					if (typeof onSuccess === 'function') {
						onSuccess();
					}
				} else if (typeof onError === 'function') {
					onError(error.message);
				}
			} catch (error) {
				if (typeof onError === 'function') {
					onError(error.message);
				}
			}

			setUser(null);

			toLogin();
		},
		[user, toLogin],
	);

	const register = useCallback(
		async (data, cb = {}) => {
			const { onSuccess, onError } = cb;

			try {
				const response = await signup(data);

				const { error } = response.data;
				if (!error) {
					if (typeof onSuccess === 'function') {
						onSuccess();
					}
					toLogin();
				} else if (typeof onError === 'function') {
					onError(error.message);
				}
			} catch (error) {
				if (typeof onError === 'function') {
					onError(error.message);
				}
			}
		},
		[toLogin],
	);

	const loadCurrentUser = useCallback(async () => {
		try {
			const response = await getProfile('me');

			const { data } = response;

			isLoading.current = false;

			if (!data.error) {
				setUser(data);
			} else {
				setUser(null);
				toLogin();
			}
		} catch {
			isLoading.current = false;
			setUser(null);
			toLogin();
		}
	}, [toLogin]);

	const refreshAccessToken = useCallback(async (siteId = null, cb = {}) => {
		const { onSuccess, onError } = cb;

		try {
			const response = await getRefreshToken(siteId);

			isLoading.current = false;

			const { error } = response.data;

			if (!error) {
				if (typeof onSuccess === 'function') {
					onSuccess(response.data);
				}
			} else {
				if (typeof onError === 'function') {
					onError(error.message);
				}
			}
		} catch (error) {
			isLoading.current = false;

			if (typeof onError === 'function') {
				onError(error.message);
			}
		}
	}, []);

	const switchSite = useCallback(
		(id, cb = {}) => {
			const { onSuccess, onError } = cb;

			refreshAccessToken(id, {
				onSuccess: (data) => {
					setUser((old) => ({ ...old, siteId: id }));

					if (typeof onSuccess === 'function') {
						onSuccess(data);
					}
				},
				onError: onError,
			});
		},
		[refreshAccessToken],
	);

	useEffect(() => {
		if (authenticationRequired) {
			if (token) {
				if (!user && !isLoading.current) {
					isLoading.current = true;

					loadCurrentUser();
				}
			} else {
				setUser(null);
			}
		}
	}, [authenticationRequired, user, token, loadCurrentUser, toLogin]);

	useEffect(() => {
		if (authenticationRequired && user && expirationDate) {
			const timeToExpire =
				expirationDate.getTime() - new Date().getTime();

			const timeToRefresh = timeToExpire - 90 * 1000; // Send a request 90 seconds before it expires.

			if (timeToRefresh <= 0 && !isLoading.current) {
				isLoading.current = true;

				refreshAccessToken(user.siteId);
			}
		}
	}, [authenticationRequired, user, expirationDate, refreshAccessToken]);

	return (
		<AuthContext.Provider
			value={{
				user,
				token,
				login,
				logout,
				register,
				setUser,
				loadCurrentUser,
				refreshAccessToken,
				switchSite,
				toHome,
				toLogin,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;

export const useAuth = () => {
	return useContext(AuthContext);
};
