import { useMemo } from 'react';

import { DesktopMenu, MobileMenu } from 'menu';

import useSettings from './useSettings';

const filterMenuItems = (role, menu, settings) => {
	menu.forEach((item, index) => {
		if (role.level < 90) {
			const key = item.name || item.url;

			if (settings[key]) {
				item.settings = Object.assign(
					{},
					item.settings || {},
					settings[key] || {},
				);

				if (
					item.settings.roles &&
					!item.settings.roles.includes(role.id)
				) {
					item.visible = false;
				}

				if (item.settings.level && item.settings.level > role.level) {
					item.visible = false;
				}
			}
		}

		if (item.visible === false) {
			delete menu[index];
		}

		if (item.children) {
			filterMenuItems(role, item.children, settings);
		}
	});
};

function useMenu(name = '', user) {
	const { getSetting } = useSettings();

	const menuSettings = getSetting('menu', {
		data: { level: 80 },
		expenses: { level: 3 },
		'/expenses/expenditure': { level: 4 },
		'/expenses/purchases': { roles: [2, 6, 7] },
		'/expenses/inventory': { roles: [2, 6, 7] },
		'/approvals': {
			level: 80,
		},
		reports: { level: 50 },
		sales: { level: 4 },
		'/sales/orders': { level: 4 },
		'/sales/orders/payment-notices': { level: 5 },
		'/sales/insurance': { level: 5 },
		'/sales/insurance/policy': { level: 6 },
		'/sales/insurance/premium': { roles: [2] },
		'/sales/receipts': { level: 4 },
		'/settings/general': { level: 80 },
		'/settings/permission': { level: 80 },
		'/settings/database': { level: 80 },
	});

	const items = useMemo(() => {
		if (user && user.role && menuSettings) {
			const menu =
				name?.toLowerCase() === 'mobile' ? MobileMenu : DesktopMenu;

			const _items = menu.map((item) => ({ ...item }));

			filterMenuItems(user.role, _items, menuSettings);

			return _items;
		}

		return [];
		// eslint-disable-next-line
	}, [name, user]);

	return { name, items };
}

export default useMenu;
