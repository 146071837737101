import { useAuth } from './useAuth';
import { useData } from './useQuery';

function useSite(id) {
	const { user } = useAuth();

	if (!id) {
		id = user?.siteId;
	}

	const site = useData(['sites', id]);

	return site;
}

export default useSite;
