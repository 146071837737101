import Home from './home';
import Sales from './sales';
import Expenses from './expenses';
import Data from './data';
import Reports from './reports';
import Settings from './settings';

export const DesktopMenu = [Home, Sales, Expenses, Data, Reports, Settings];

export const MobileMenu = DesktopMenu;
