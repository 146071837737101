import PaidIcon from '@mui/icons-material/Paid';
import StoreIcon from '@mui/icons-material/Store';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import GarageIcon from '@mui/icons-material/Garage';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import InventoryIcon from '@mui/icons-material/Inventory';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';

const icons = {
	payment: PaidIcon,
	store: StoreIcon,
	expense: RequestQuoteIcon,
	purchase: ShoppingCartIcon,
	car: GarageIcon,
	money: LocalAtmIcon,
	return: AssignmentReturnIcon,
	bag: ShoppingBagIcon,
	inventory: InventoryIcon,
	backward: ArrowBackIcon,
	forward: ArrowForwardIcon,
	add: BookmarkAddIcon,
	remove: BookmarkRemoveIcon,
	available: BookmarkAddedIcon,
};

const Expenses = {
	name: 'expenses',
	type: 'group',
	title: 'ค่าใช้จ่าย',
	children: [
		{
			type: 'collapse',
			title: 'รายจ่าย',
			icon: icons.payment,
			url: '/expenses/expenditure',
			children: [
				{
					type: 'item',
					title: 'เจ้าหนี้',
					icon: icons.store,
					url: '/expenses/expenditure/payable',
				},
				{
					type: 'item',
					title: 'ภาษีขนส่ง',
					icon: icons.car,
					url: '/expenses/expenditure/pay-tax',
				},
				{
					type: 'item',
					title: 'ทอนคืนภาษีลูกค้า',
					icon: icons.return,
					url: '/expenses/expenditure/return-tax',
				},
				{
					type: 'item',
					title: 'ค่าใช้จ่าย',
					icon: icons.expense,
					url: '/expenses/expenditure/expense',
				},
				{
					type: 'item',
					title: 'จ่ายอื่นๆ',
					icon: icons.money,
					url: '/expenses/expenditure/payout',
				},
			],
		},
		{
			type: 'collapse',
			title: 'ข้อมูลการซื้อ',
			icon: icons.purchase,
			url: '/expenses/purchases',
			children: [
				{
					type: 'item',
					title: 'รายการซื้อ',
					icon: icons.bag,
					url: '/expenses/purchases/orders',
				},
				{
					type: 'item',
					title: 'ร้านค้า',
					icon: icons.store,
					url: '/expenses/purchases/stores',
				},
			],
		},
		{
			type: 'collapse',
			title: 'สต็อก',
			icon: icons.inventory,
			url: '/expenses/inventory',
			children: [
				{
					type: 'item',
					title: 'รายการคงเหลือ',
					icon: icons.available,
					url: '/expenses/inventory/items',
				},
			],
		},
	],
};

export default Expenses;
