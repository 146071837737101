import { useCallback, useMemo } from 'react';

import { useData } from './useQuery';
import useSite from './useSite';

function useSettings() {
	const site = useSite();

	const global = useData('settings');

	const settings = useMemo(() => {
		const items = { ...(global || {}) };

		if (site && site.settings) {
			Object.assign(items, site.settings);
		}

		return items;
	}, [global, site]);

	const getSettings = useCallback(() => settings, [settings]);

	const getSetting = useCallback(
		(name = '', defaultValue = null) => {
			if (settings) {
				return settings[name] || defaultValue;
			}
			return defaultValue;
		},
		[settings],
	);

	return {
		getSettings,
		getSetting,
	};
}

export default useSettings;
