import { forwardRef, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import MenuItems from './MenuItems';

const NavCollapse = (props) => {
	const [open, setOpen] = useState(props.selected);

	const handleClick = () => {
		setOpen(!open);
	};

	useEffect(() => {
		setOpen(props.selected);
	}, [props.selected]);

	const Icon = props.icon;
	const menuIcon = props.icon ? <Icon /> : <FiberManualRecordIcon />;

	let listItemProps = {};
	if (props.type === 'item' && props.url) {
		let itemTarget = props.target ? '_blank' : undefined;

		listItemProps = {
			component: forwardRef((linkProps, ref) => (
				<Link
					ref={ref}
					{...linkProps}
					to={props.url}
					target={itemTarget}
				/>
			)),
		};
		if (props?.external) {
			listItemProps = {
				component: 'a',
				href: props.url,
				target: itemTarget,
			};
		}
	}

	return (
		<>
			<ListItemButton
				{...listItemProps}
				selected={props.selected}
				onClick={handleClick}
				sx={{
					borderRadius: '8px',
					gap: '8px',
					marginBottom: '2px',
					padding: '8px',
				}}
			>
				<ListItemIcon>{menuIcon}</ListItemIcon>

				<ListItemText
					primary={
						<Typography variant="h6">{props.title}</Typography>
					}
					sx={{
						margin: 0,
					}}
				/>

				{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
			</ListItemButton>
			<Collapse
				in={open}
				timeout="auto"
				unmountOnExit
				sx={{
					position: 'relative',
					'&:after': {
						content: "''",
						position: 'absolute',
						left: 0,
						top: 0,
						height: '100%',
						width: '2px',
						background: 'primary.light',
					},
				}}
			>
				<List
					component="div"
					sx={{
						backgroundColor: 'secondary.lighter',
						borderRadius: 1,
						p: 1,
					}}
				>
					<MenuItems {...props} />
				</List>
			</Collapse>
		</>
	);
};

export default NavCollapse;
