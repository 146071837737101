import { Link } from 'react-router-dom';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

function ListItemLink({
	to,
	icon,
	text,
	primary,
	secondary,
	children,
	...props
}) {
	return (
		<ListItemButton component={Link} to={to} {...props}>
			{children}
			{Boolean(icon) && <ListItemIcon>{icon}</ListItemIcon>}
			{(Boolean(text) || Boolean(primary) || Boolean(secondary)) && (
				<ListItemText
					primary={text || primary}
					secondary={secondary}
					sx={{ display: 'flex', justifyContent: 'space-between' }}
				/>
			)}
		</ListItemButton>
	);
}

export default ListItemLink;
