import { useEffect, useMemo } from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';

import Breadcrumbs from 'components/Breadcrumbs';

import { APP_LOGIN_PATH } from 'config';

import useDocumentTitle from 'hooks/useDocumentTitle';
import AuthProvider, { useAuth } from 'hooks/useAuth';
import NotificationProvider from 'hooks/useNotifications';
import NavigationProvider, { useNavigation } from 'hooks/useNavigation';
import useMenu from 'hooks/useMenu';

import Header from './Header';
import Navigation from './Navigation';

import './style.scss';

function Content() {
	const theme = useTheme();

	const downMD = useMediaQuery(theme.breakpoints.down('md'));

	const { open, setOpen } = useNavigation();

	const { user } = useAuth();

	const menu = useMenu(downMD ? 'mobile' : 'desktop', user);

	const header = useMemo(() => {
		if (user && user.sites) {
			const site = user.sites.find((item) => item.id === user.siteId);
			if (site) {
				return { title: site.title, logo: site.logo };
			}
		}

		return {};
	}, [user]);

	useDocumentTitle(header?.title);

	useEffect(() => {
		setOpen(!downMD);
	}, [setOpen, downMD]);

	const handleDrawerToggle = () => {
		setOpen(!open);
	};

	const classes = ['main-layout'];

	if (downMD) {
		classes.push('mobile-menu');
	} else {
		classes.push('desktop-menu');
	}

	if (open) {
		classes.push('is-nav-active');
	}

	return (
		<div className={classes.join(' ')}>
			<Header
				title={header?.title}
				logo={header?.logo}
				open={open}
				onToggle={handleDrawerToggle}
				mobile={downMD}
			/>
			<Navigation menu={menu} />
			<main className="main-content">
				<Breadcrumbs navigation={menu} />
				<Outlet />
			</main>
			{downMD && (
				<div
					className="content-overlay"
					onClick={handleDrawerToggle}
				></div>
			)}
		</div>
	);
}

function MainContent() {
	const { token } = useAuth();
	if (!token) {
		return <Navigate to={APP_LOGIN_PATH} />;
	}

	return (
		<NavigationProvider>
			<Content />
		</NavigationProvider>
	);
}

const MainLayout = () => {
	return (
		<AuthProvider authenticationRequired={true}>
			<NotificationProvider>
				<MainContent />
			</NotificationProvider>
		</AuthProvider>
	);
};

export default MainLayout;
