import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import DiscountIcon from '@mui/icons-material/Discount';
import EmailIcon from '@mui/icons-material/Email';

import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';

const icons = {
	home: HomeIcon,
	dashboard: DashboardIcon,
	update: PublishedWithChangesIcon,
	bank: AssuredWorkloadIcon,
	discount: DiscountIcon,
	message: EmailIcon,
};

const home = {
	name: 'home',
	type: 'group',
	title: 'หน้าหลัก',
	icon: icons.home,
	children: [
		{
			type: 'item',
			title: 'ภาพรวม',
			icon: icons.dashboard,
			url: '/',
		},
		{
			type: 'item',
			title: 'ข้อความ',
			icon: icons.message,
			url: '/posts',
		},
		{
			type: 'collapse',
			title: 'รายการรออนุมัติ',
			icon: icons.update,
			url: '/approvals',
			children: [
				{
					type: 'item',
					title: 'ธุรกรรม',
					icon: icons.bank,
					url: '/approvals/transactions',
				},
				{
					type: 'item',
					title: 'ส่วนลด',
					icon: icons.discount,
					url: '/approvals/orders',
				},
			],
		},
	],
};

export default home;
