import { useContext, createContext, useState } from 'react';

const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {
	const [notifications, setNotifications] = useState([]);

	return (
		<NotificationsContext.Provider
			value={{ notifications, setNotifications }}
		>
			{children}
		</NotificationsContext.Provider>
	);
};

export default NotificationsProvider;

export const useNotifications = () => {
	return useContext(NotificationsContext);
};
