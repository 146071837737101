import PeopleIcon from '@mui/icons-material/People';
import StoreIcon from '@mui/icons-material/Store';
import InventoryIcon from '@mui/icons-material/Inventory';

const icons = {
	user: PeopleIcon,
	company: StoreIcon,
	product: InventoryIcon,
};

const Data = {
	name: 'data',
	type: 'group',
	title: 'ข้อมูลระบบ',
	children: [
		{
			type: 'item',
			title: 'สินค้า',
			icon: icons.product,
			url: '/products',
		},
		{
			type: 'item',
			title: 'ข้อมูลผู้ใช้งาน',
			icon: icons.user,
			url: '/users',
		},
		{
			type: 'item',
			title: 'ข้อมูลบริษัท',
			icon: icons.company,
			url: '/sites',
		},
	],
};

export default Data;
