/**
 * Returns a typography options object.
 *
 * @param {string} fontFamily A font family.
 * @return {object} A typography options object.
 */
const Typography = ({ fontFamily = 'Roboto', fontSize = '1.4rem' }) => {
	fontFamily = [
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		fontFamily,
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(',');

	return {
		htmlFontSize: 10,
		fontFamily,
		fontSize: 14,
		fontWeightBold: 600,
		h1: {
			fontWeight: 600,
			fontSize: '2.6rem',
			lineHeight: 1.21,
			'@media (minWidth:2000px)': {
				fontSize: '3.2rem',
			},
		},
		h2: {
			fontWeight: 600,
			fontSize: '2.2rem',
			lineHeight: 1.5,
			'@media (minWidth:2000px)': {
				fontSize: '3rem',
			},
		},
		h3: {
			fontWeight: 600,
			fontSize: '2rem',
			lineHeight: 1.33,
			'@media (minWidth:2000px)': {
				fontSize: '2.2rem',
			},
		},
		h4: {
			fontWeight: 600,
			fontSize: '1.8rem',
			lineHeight: 1.4,
			'@media (minWidth:2000px)': {
				fontSize: '2rem',
			},
		},
		h5: {
			fontWeight: 600,
			fontSize: '1.6rem',
			lineHeight: 1.5,
			'@media (minWidth:2000px)': {
				fontSize: '1.8rem',
			},
		},
		h6: {
			fontWeight: 600,
			fontSize,
			lineHeight: 1.57,
			'@media (minWidth:2000px)': {
				fontSize: '1.6rem',
			},
		},
		caption: {
			fontSize: '1.2rem',
			lineHeight: 1.66,
			'@media (minWidth:2000px)': {
				fontSize: '1.4rem',
			},
		},
		body1: {
			fontSize,
			lineHeight: 1.57,
			'@media (minWidth:2000px)': {
				fontSize: '1.6rem',
			},
		},
		body2: {
			fontSize: '1.3rem',
			lineHeight: 1.66,
			'@media (minWidth:2000px)': {
				fontSize: '1.5rem',
			},
		},
		subtitle1: {
			fontSize,
			fontWeight: 600,
			lineHeight: 1.57,
			'@media (minWidth:2000px)': {
				fontSize: '1.6rem',
			},
		},
		subtitle2: {
			fontSize: '1.3rem',
			fontWeight: 600,
			lineHeight: 1.66,
			'@media (minWidth:2000px)': {
				fontSize: '1.5rem',
			},
		},
		overline: {
			fontSize: '1.3rem',
			lineHeight: 1.66,
			'@media (minWidth:2000px)': {
				fontSize: '1.5rem',
			},
		},
		button: {
			fontSize,
			textTransform: 'capitalize',
			'@media (minWidth:2000px)': {
				fontSize: '1.6rem',
			},
		},
		strong: {
			fontWeight: 600,
		},
	};
};

export default Typography;
