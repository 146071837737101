import { useState } from 'react';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Popover from '@mui/material/Popover';

import NotificationsIcon from '@mui/icons-material/Notifications';
import InfoIcon from '@mui/icons-material/Info';

import ListItemLink from 'components/ListItemLink';

import { useNotifications } from 'hooks/useNotifications';

const Notifications = () => {
	const [anchorEl, setAnchorEl] = useState(null);

	const { notifications } = useNotifications();

	const handleToggle = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<Box sx={{ flexShrink: 0 }}>
			<IconButton
				sx={{
					bgcolor: open ? 'primary.lighter' : 'transparent',
					color: open ? 'primary.main' : 'secondary.main',
					'&:hover': {
						bgcolor: open ? 'primary.lighter' : 'secondary.lighter',
					},
				}}
				onClick={handleToggle}
			>
				<Badge
					color="primary"
					{...(notifications.length > 0
						? { badgeContent: notifications.length }
						: {})}
				>
					<NotificationsIcon />
				</Badge>
			</IconButton>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: -8,
					horizontal: 'left',
				}}
				sx={{ zIndex: 900 }}
			>
				<Card
					sx={{
						minWidth: 200,
					}}
				>
					<List
						subheader={
							<ListSubheader component="div">
								<strong>แจ้งเตือน</strong>
							</ListSubheader>
						}
						sx={{
							p: 0,
							'& .MuiButtonBase-root': {
								gap: 1,
							},
						}}
					>
						{notifications.length === 0 && (
							<ListItemLink text="ยังไม่มีรายการแจ้งเตือน" />
						)}
						{notifications.length > 0 &&
							notifications.map((item, index) => (
								<ListItemLink
									key={`item-${index}`}
									text={item.content}
									primary={item.primary}
									secondary={item.secondary}
									icon={<InfoIcon color="info" />}
									to={item.link}
									selected={item.selected}
								/>
							))}
					</List>
				</Card>
			</Popover>
		</Box>
	);
};

export default Notifications;
